.leave-card {
  padding: 12px;
  min-width: 210px;
  width: auto;
  flex: 1 1 auto;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;

  .leave-card--content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .leave-card--details {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
