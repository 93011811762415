@import '~antd/dist/antd.less';
@import './custom-theme.less';

svg {
  vertical-align: baseline;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@success-color: #28a745;
@warning-color: #eca52b;
@info-color: #17a2b8;
@dark-color: #343a40;
@light-dark-color: #6c757d;
@buttons:
        dark @dark-color,
        lightdark @light-dark-color,
        success @success-color,
        warning @warning-color,
        info @info-color;
.getButtons(@index:1) when(@index <= length(@buttons)) {
  @name: extract(extract(@buttons, @index),1);
  @color: extract(extract(@buttons, @index),2);
  @colorHoverFocus: fade(@color, 75%);
  @colorHoverBorderFocus: fade(@color, 10%);
  @colorActive: darken(@color, 10%);
  .ant-btn-@{name}{
    color: #fff;
    background-color: @color;
    border-color: @color;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .ant-btn-@{name}-disabled,
  .ant-btn-@{name}.disabled,
  .ant-btn-@{name}[disabled],
  .ant-btn-@{name}-disabled:hover,
  .ant-btn-@{name}.disabled:hover,
  .ant-btn-@{name}[disabled]:hover,
  .ant-btn-@{name}-disabled:focus,
  .ant-btn-@{name}.disabled:focus,
  .ant-btn-@{name}[disabled]:focus,
  .ant-btn-@{name}-disabled:active,
  .ant-btn-@{name}.disabled:active,
  .ant-btn-@{name}[disabled]:active,
  .ant-btn-@{name}-disabled.active,
  .ant-btn-@{name}.disabled.active,
  .ant-btn-@{name}[disabled].active {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .ant-btn-@{name}:hover,
  .ant-btn-@{name}:focus {
    color: #fff;
    background-color: @colorHoverFocus;
    border-color: @colorHoverBorderFocus;
  }
  .ant-btn-@{name}:active,
  .ant-btn-@{name}.active {
    color: #fff;
    background-color: @colorActive;
    border-color: @colorActive;
  }
  .ant-btn-background-ghost.ant-btn-@{name} {
    color: @color;
    background: transparent;
    border-color: @color;
    text-shadow: none;
  }
  .ant-btn-background-ghost.ant-btn-@{name}:hover,
  .ant-btn-background-ghost.ant-btn-@{name}:focus {
    color: @colorHoverFocus;
    background: transparent;
    border-color: @colorHoverFocus;
  }
  .ant-btn-background-ghost.ant-btn-@{name}:active,
  .ant-btn-background-ghost.ant-btn-@{name}.active {
    color: @color;
    background: transparent;
    border-color: @colorActive;
  }
  .getButtons(@index + 1);
};

.getButtons();

.disable-animation:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
background-color: #F4326C;
outline: 1px solid white;
}
