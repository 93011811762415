.leave-details-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 16px;
  overflow: auto; 
  width: 100%;
}

.announcements-list {
  height: 130px;
  overflow: auto;

  .ant-list-item {
    padding-left: 0;
    .ant-list-item-meta {
      align-items: center;
    }
  }
}

.user-leave-list {
  height: 225px;
  overflow: auto;
}
.celebration-list {
  height: 285px;
  overflow: auto;
}

