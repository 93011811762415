.user-info {
  font-size: 14px;
  color: black;
}
.info-border {
  padding: 10px;

  border: 0.5px solid grey;
}

