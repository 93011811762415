
@mobile:    ~"only screen and (max-width: 768px)";

.layout-wrapper {
  height: 100vh;

  .sidebar-logo {
    min-height: 80px;
    min-width: 80px;
    max-height: 120px;
    max-width: 100px;
    padding: 10px 12px;
    margin: auto;

    img {
      height: 100%;
      width: 100%;
      cursor: pointer;
      border-radius: 4px;
    }
  }
  @media @mobile {
    .sidebar-logo {
      min-height: 60px;
      min-width: 70px;
      max-height: 120px;
      max-width: 100px;
      padding:10px 0px 10px 10px;
      img {
        height: 80%;
        width: 80%;
      }
    }
  }

  .sidebar-collapse-btn > *{
    position: absolute;
    bottom: 19px;
    right: -16px;
    z-index: 999;
    border:1px solid red;
    border-radius: 20px !important;
  }

  .content-wrapper {
    .main-header {
      z-index: 99;
    }

    .main-content {
      height: calc(100vh - 65px);
      overflow: auto;
      // margin-top: 65px;
      padding: 34px 24px;
    }

    @media @mobile {
      .main-content {
        padding: 20px 10px;
      }
    }
  }
}


.layout-wrapper{
  .menu-button{
    display: none;
  }
  
  @media @mobile {
    .menu-button{
      display: inline;
    }
    .ant-layout-header{
      padding:0px 0px 0px 20px !important;
    }

    .sidebar{
       display: block;
    }
    .hide-mobile-sidebar{
      display: none;
    }
  }
}

