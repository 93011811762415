.login-wrapper {
  padding: 1rem;
  height: 100vh;

  .login-banner {
    border-radius: 1.5rem;
    height: inherit;
    text-align: center;
    background: #ddd9ff66 url("../../assets/images/abstract_lines.png")
      no-repeat top;
    background-size: 100% 100%;

    .login-desc {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  .login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .form-wrapper {
      margin: auto;
      max-width: 500px;
    }
  }
  @media only screen and (max-width: 768px) {
    .login-banner {
      display: none;
      .login-desc {
        display: none;
      }
    }
  }
}

